@import 'foundation';
@import "reset";
@import "base";
@import "public";
// --------------- custom faoundation settings here --------------------
$global-margin: 20px;
$global-padding: 20px;
$prototype-spacing-breakpoints: true;
$prototype-spacers-count: 8;
$breakpoint-classes: (small medium large xlarge xxlarge);
$breakpoints: (
	small: 0px,
	medium: 640px,
	large: 1024px,
	xlarge: 1200px,
	xxlarge: 1600px,
);
@include foundation-xy-grid-classes;
@include foundation-prototype-spacing;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-text-alignment;
body{
	opacity: 0;
	transition: all 1s;
	&.is-load{
		opacity: 1;
	}
}
// a{
// 	&:hover{
// 		text-decoration: underline;
// 	}
// }
.m-outWrap{
	margin-left: 200px;
	padding: 50px;
	max-width: 1704px;
	@include breakpoint(medium down){
		margin-left: 0;
		padding: 55px 20px 20px 20px;
	};
}
.menuWrap{
	z-index: 69;
	position: fixed;
	top: 50px;
	left: 50px;
	@include breakpoint(medium down){
		top: 30px;
		left: 20px;
	};
	&.is-white{
		.logo svg{fill: #fff;}
		.menuList >li{color: #fff;}
		.menuList .subMenuList >li{color: #fff;}
		.copyright{color: #fff;}
	}
	.logo{
		margin-bottom: 39px;
		svg{fill: #3e3a39;}
	}
	.menuList{
		margin-bottom: 37px;
		>li{
			font-family: $en-ch;
			font-size: 12px;
			color: #3e3a39;
			@include mb(10px);
			&:hover{
				>a{color: #9fa0a0;}
			}
			&.current{
				>a{color: #9fa0a0;}
				.subMenuList{display: block;}
			}
			>a{
				transition: all .3s;
			}
			@include breakpoint(xxlarge){
				font-size: 15px;
			};
		}
		.fakea{
			cursor: pointer;
			transition: all .3s;
			&:hover{
				color: #9fa0a0;
			}
			&.current{
				color: #9fa0a0;
			}
		}
		.subMenuList{
			margin-top: 10px;
			padding-left: 10px;
			display: none;
			>li{
				font-family: $en-ch;
				font-size: 12px;
				color: #3e3a39;
				transition: all .3s;
				@include mb(10px);
				&:hover{
					color: #9fa0a0;
				}
				&.current{
					color: #9fa0a0;
				}
				@include breakpoint(xxlarge){
					font-size: 15px;
				};
			}
		}
	}
	.copyright{
		font-family: $en-ch;
		font-size: 12px;
		color: #3e3a39;
		@include breakpoint(xxlarge){
			font-size: 15px;
		};
	}
}
.index-bannerWrap{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	&.vegas-container{
		height: 100vh !important;
	}
}
.projectsList{
	li{
		font-family: $en-ch;
		font-size: 12px;
		line-height: 1.5;
		color: #4c4948;
		border: 3px solid #fff;
		opacity: 0;
		@include breakpoint(xxlarge){
			font-size: 15px;
		};
		&:hover{
			.pic-area .pic{
				transform: scale(1.1);
				// opacity: .6;
			}
		}
	}
	.pic-area{
		padding-top: 100%;
		margin-bottom: 5px;
		position: relative;
		overflow: hidden;
		.pic{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 1.5s;
		}
	}
	.title{}
	.info{}
	.date{}
}
.projectsDetailWrap{
	position: relative;
	max-width: 1200px;
	// &:hover{
	// 	.pd-prev, .pd-next{opacity: 1;}
	// }
	.projectsDetailSlider{
		li{
			position: relative;
			.pic{
				padding-top: 66.67%;
				@include breakpoint(medium down){
					background-position: center center !important;
				};
			}
		}
		.slider-prev{
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			cursor: pointer;
			// cursor: url(../images/slider-prev.svg), auto;
		}
		.slider-next{
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			cursor: pointer;
			// cursor: url(../images/slider-next.svg), auto;
		}
	}
	.shopDetailSlider{
		li{
			position: relative;
			.pic{
				padding-top: 100%;
				@include breakpoint(medium down){
					background-position: center center !important;
				};
			}
		}
	}
	.pd-prev, .pd-next{
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 6px;
		width: 36px;
		height: 36px;
		transition: all .3s;
		background-color: rgba(245, 245, 245, 0.9);
		padding: 8px;
		opacity: 0;
		pointer-events: none;
		@include breakpoint(medium down){
			display: none !important;
		};
		&.is-show{
			opacity: 1;
		}
		>img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			max-height: 45%;
		}
	}
	.pd-next{
		left: auto;
		right: 6px;
	}
}
.projectsDetail-title-area{
	font-family: $en-ch;
	font-size: 12px;
	line-height: 1.5;
	color: #4c4948;
	position: fixed;
	z-index: 39;
	left: 50px;
	bottom: 50px;
	max-width: 114px;
	@include breakpoint(xxlarge){
		font-size: 15px;
		max-width: 150px;
	};
	@include breakpoint(medium down){
		position: relative;
		max-width: 100%;
		top: 0;
		left: 0;
		padding: 0 20px;
		margin-bottom: 70px;
	};
	.title{
		margin-bottom: 20px;
		@include breakpoint(medium down){
			margin-bottom: 10px;
		};
	}
	.content{
		margin-bottom: 20px;
		@include breakpoint(medium down){
			margin-bottom: 10px;
		};
	}
	.control{
		font-weight: 700;
	}
}
.infoWrap{
	font-family: $en-ch;
	font-size: 13px;
	line-height: 1.5;
	color: #3e3a39;
	max-width: 800px;
	@include breakpoint(xxlarge){
		font-size: 15px;
	};
	.banner{
		margin-bottom: 27px;
		padding-top: 75%;
	}
	.socialLink{
		font-weight: 700;
		margin-bottom: 10px;
		@include breakpoint(medium down){
			margin-bottom: 4px;
		};
	}
	.contact{
		font-weight: 700;
		margin-bottom: 24px;
		span{
			font-weight: 400;
			color: #000;
		}
	}
	.introduce{
		border-top: 1px solid #3e3a39;
		border-bottom: 1px solid #3e3a39;
		padding: 22px 0;
		margin-bottom: 24px;
	}
	.award{
		strong, b{font-weight: 700;}
	}
}
.exhibitionDetailWrap{
	margin-top: 20px;
	max-width: 1200px;
	@include breakpoint(medium down){
		margin-top: 14px;
	};
	.cat-area{
		font-family: $en-ch;
		font-size: 13px;
		color: #9fa0a0;
		margin-bottom: 11px;
		@include breakpoint(xxlarge){
			font-size: 16px;
		};
		@include breakpoint(medium down){
			font-size: 12px;
			margin-bottom: 6px;
		};
		span{
			// font-weight: 600;
			// color: #9fa0a0;
			margin-right: 6px;
		}
	}
	.title{
		font-family: $en-ch;
		font-weight: 700;
		font-size: 28px;
		letter-spacing: 1px;
		line-height: 1.3;
		color: #3e3a39;
		margin-bottom: 7px;
		@include breakpoint(xxlarge){
			font-size: 31px;
		};
		@include breakpoint(medium down){
			font-size: 16px;
			margin-bottom: 3px;
		};
	}
	.note{
		font-family: $en-ch;
		font-size: 13px;
		line-height: 1.5;
		color: #9fa0a0;
		margin-bottom: 28px;
		@include breakpoint(xxlarge){
			font-size: 16px;
		};
		@include breakpoint(medium down){
			font-size: 12px;
			margin-bottom: 20px;
		};
	}
	.content{
		font-family: $content-family;
		font-size: 12px;
		line-height: 1.8;
		color: #3e3a39;
		@include breakpoint(xxlarge){
			font-size: 15px;
		};
	}
	.back-area{
		margin-top: 30px;
		padding-top: 15px;
		border-top: 1px solid #3e3a39;
		font-family: $en-ch;
		// font-weight: 600;
		font-size: 12px;
		@include breakpoint(xxlarge){
			font-size: 15px;
		};
		@include breakpoint(medium down){
			padding-top: 12px;
			margin-bottom: 30px;
		};
		// .back{
		// 	color: #e60012;
		// }
		.other-area{
			font-weight: 700;
		}
	}
}
.slick-dotWrap{
	max-width: 1200px;
	text-align: right;
	margin-top: 16px;
	@include breakpoint(medium down){
		margin-top: 12px;
	};
	.slick-dots li{
		display: inline-block;
		vertical-align: middle;
		width: 10px;
		height: 10px;
		background-color: #c9caca;
		border-radius: 50%;
		transition: all .3s;
		cursor: pointer;
		@include mr(14px);
		button{display: none;}
		@include breakpoint(medium down){
			width: 8px;
			height: 8px;
			@include mr(11px);
		};
		&.slick-active{
			background-color: #595757;
		}
	}
}
.slick-nav{
	// border-top: 1px solid #9fa0a0;
	// padding-top: 16px;
	margin-top: 6px;
	max-width: 1200px;
	@include breakpoint(medium down){
		border-top: none;
		padding-top: 0;
	};
	li{
		width: 120px;
		height: 120px;
		margin-right: 6px;
		cursor: pointer;
		@include breakpoint(medium down){
			width: 20vw;
			height: 20vw;
		};
	}
}
.shop-nav{
	margin-top: 6px;
	li{
		width: 145px;
		height: 145px;
		margin-right: 6px;
		cursor: pointer;
		@include breakpoint(medium down){
			width: 20vw;
			height: 20vw;
		};
	}
}
.shop-pic-container{
	margin-right: 44px;
}
.shop-article-container{
	font-family: $en-ch;
	font-size: 12px;
	line-height: 1.8;
	color: #231815;
	@include breakpoint(xxlarge){
		font-size: 15px;
	};
	@include breakpoint(medium down){
		margin: 40px auto;
	};
	.title{
		font-weight: 700;
		margin-bottom: 18px;
	}
	.content{
		margin-bottom: 20px;
	}
	.price{
		font-weight: 700;
		margin-bottom: 24px;
	}
	.buy{
		a{
			color: #fff;
			text-align: center;
			background-color: #231815;
			width: 130px;
			padding: 10px 14px;
			display: inline-block;
		}
	}
}
.mobile-menuWrap{
	z-index: 69;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	padding: 15px 20px 10px;
	background-color: #fff;
	&.is-index{
		background-color: transparent;
		text-align: left;
		.logo svg{fill: #fff;}
		.mobile-menu{color: #fff;}
	}
	.logo{
		svg{fill: #3e3a39;}
	}
	.mobile-menu{
		font-family: $en-ch;
		font-size: 13px;
		color: #595757;
		position: absolute;
		top: 22px;
		right: 20px;
	}
}
.mobile-menu-space{
	height: 35px;
}
.footerWrap{
	font-family: $en-ch;
	font-size: 12px;
	line-height: 1.5;
	color: #3e3a39;
	padding: 20px;
	.socialLink{
		margin-bottom: 2px;
	}
	.contact{
		span{
			color: #9fa0a0;
		}
	}
}
.mobile-controler{
	font-family: $en-ch;
	font-size: 14px;
	color: #595757;
	padding: 30px 20px;
	@include breakpoint(large){
		display: none;
	};
}
.mobile-menu-container{
	background-color: #fff;
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding: 20px;
	display: none;
	.close{
		position: absolute;
		top: 30px;
		right: 20px;
	}
	.menuList{
		margin-bottom: 37px;
		padding-top: 44px;
		>li{
			font-family: $en-ch;
			font-size: 16px;
			color: #3e3a39;
			@include mb(12px);
			&.current{
				>a{color: #9fa0a0;}
				.subMenuList{display: block;}
			}
		}
		.fakea{
			cursor: pointer;
			&.current{
				color: #9fa0a0;
			}
		}
		.subMenuList{
			margin-top: 10px;
			padding-left: 10px;
			display: none;
			>li{
				font-family: $en-ch;
				font-size: 14px;
				color: #3e3a39;
				@include mb(10px);
				&.current{
					>a{color: #9fa0a0;}
				}
			}
		}
	}
	.copyright{
		font-family: $en-ch;
		font-size: 14px;
		color: #9E9E9F;
	}
	.bottom-area{
		font-family: $en-ch;
		font-size: 12px;
		line-height: 1.5;
		color: #3e3a39;
		position: absolute;
		left: 20px;
		bottom: 30px;
		.socialLink{
			margin-bottom: 2px;
		}
		.contact{
			span{
				color: #9fa0a0;
			}
		}
	}
}
.mobile-index-menu{
	z-index: 1;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 20px;
	.menuList{
		margin-bottom: 37px;
		padding-top: 50px;
		>li{
			font-family: $en-ch;
			font-size: 16px;
			color: #fff;
			@include mb(12px);
			&.current{
				>a{color: #9fa0a0;}
				.subMenuList{display: block;}
			}
		}
		.fakea{
			cursor: pointer;
			&.current{
				color: #9fa0a0;
			}
		}
		.subMenuList{
			margin-top: 10px;
			padding-left: 10px;
			display: none;
			>li{
				font-family: $en-ch;
				font-size: 14px;
				color: #fff;
				@include mb(10px);
			}
		}
	}
	.copyright{
		font-family: $en-ch;
		font-size: 14px;
		color: #fff;
	}
	.bottom-area{
		font-family: $en-ch;
		font-size: 12px;
		line-height: 1.5;
		color: #3e3a39;
		position: absolute;
		left: 20px;
		bottom: 30px;
		.socialLink{
			margin-bottom: 2px;
		}
		.contact{
			span{
				color: #9fa0a0;
			}
		}
	}
}
.shopList{
	li{
		font-family: $en-ch;
		font-size: 12px;
		line-height: 1.5;
		color: #4c4948;
		border: 3px solid #fff;
		opacity: 0;
		@include breakpoint(xxlarge){
			font-size: 15px;
		};
		&:hover{
			.pic-area{
				.pic{opacity: 0;}
				.hover-pic{opacity: 1;}
			}
		}
	}
	.pic-area{
		padding-top: 100%;
		position: relative;
		overflow: hidden;
		.pic{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.hover-pic{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
		}
	}
	.title{}
	.info{}
	.date{}
}
#preloadWrap{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
	background-color: #fff;
	svg{
		@include breakpoint(xxlarge){
			transform: scale(1.2);
		};
		.cls-1 {
		    fill: none;
		    stroke: #fff;
		    stroke-linecap: round;
		    stroke-linejoin: round;
		    stroke-width: 3px;
		}
		#coverpath{
		    stroke-dasharray: 380;
	        stroke-dashoffset: 0;
		}
	}
}