@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');

$ch-family: "メイリオ", Meiryo, HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$content-family: Arial, Helvetica, HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$en-family: 'Source Sans Pro', sans-serif;
$en-ch: 'Source Sans Pro', HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;


html,body{
	-webkit-text-size-adjust: 100%;   // for mobile fontsize
}

a{
	text-decoration: none;
	color: inherit;
	img{border: 0;}
}

:focus{
	outline: 0;
}

button{
	background-color: transparent;
	border: 0;
}

*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img{
	vertical-align: middle; //remove bottom space
	max-width: 100%;
	// max-height: 100%;
}

/* override the default IOS styles */
input, textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


/*

 \
 _\,,
"-=\~     _
   \\~___( ~
  _|/---\\_
  \        \	base.scss end

*/
